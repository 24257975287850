.App {
  text-align: center;
}

*{
  padding: 0;
  margin: 0;
}

p{
  color: #000000;
  opacity: 0.6;
}

textarea, input, button{
  outline: none;
  border: none;
}

