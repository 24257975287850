.question{
    margin-bottom: 2rem;
}
.options{
    margin-left: 2rem;
}

.option{
    display: flex;
}

.selected, .not-selected{
    padding: 5px;
    margin-bottom: 2px;

}
.selected{
    border: 1px solid #333333;
    border-radius: 40%;
}

.check-icon{
    color : green;
}

.cross-icon{
    color: red;
}