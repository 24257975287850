.container {
    width: 100%;
    margin: 20 auto;
    padding: 60px;
    background: #143f26;
    color: white;
    font-size: 30px;
}

.nav-link {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }

  .nav-link:hover {
    background-color: #ddd;
    color: black;
  }

  .right-end{
    /* position:absolute; right:0; */
  }
  .loggedOutView{
    font-size: 24px !important;
    padding: 0px !important;
  }

  .header-nav{
    padding-top: 5px !important;
  }