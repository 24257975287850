.task-submission-list {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: 1165px;
    align-items: stretch;
  }
  
  table {
    background-color: #f2f2f2;
    width: 50%;
  }
  
  
  table, td {
    border: 1px solid white;
    border-collapse: collapse;
  }
  
  thead:first-child{
    font-weight: 500;
    background-color: #721515;
    color: white;
  }
  
  thead:first-child td:last-child{
    width: 50px;
  }

tbody td{
  text-align: center;
}

  td{
    padding: 0.5em;
    text-align: left;
    height: 24px;
  }
  
  td:nth-child(2), td:nth-child(3){
    text-align: center;
  }
  
  .submissionYes {
      color: green;
      font-size: 24px;
  }

  .submissionNo {
      color: red;
      font-size: 24px;
  }

  .checkBtn{
      border: none;
      background-color: transparent;
  }

  .checkBtn:focus{
      outline: 0;
  }

.task-overview{
    flex: 1;
    text-align: left;
    padding: 0 1em 0 3em;
}

.title{
    font-size: 26px;
    font-weight: 400;
}

.selectedRow{
  background-color: #d4d4d4;
}
  