.body-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.testtext {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    writing-mode: vertical-lr;
    height: 250px;
    transform: rotate(186deg);
    font-style: italic;
}

.horizontal-task-list {
    display: flex;
}

.task-item {
    width: 60px;
    display: flex;
    justify-content: center;
    text-align: left;
}

.oneOneCell {
    width: 50%;
}

.tableData{
    width: 200px
}

.symbol-container{
    width: 600px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.symbol{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.not-graded{
    color: #b7b7b7;
}

.icon-image{
    font-size: 24px;
    /* margin-right: 10px; */
}
thead .icon-image{
    /* font-size: 24px; */
    margin-right: 10px;
    margin-left: 10px;
}

.scroll-view{
    max-width: 100%;
    overflow: auto;
}